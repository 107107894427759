var subtitles__4 = [
		
	{ "start": "0", "end": "3", "text": "How to speak the Corridor" },

	{ "start": "3.5", "end": "6", "text": "Es scheint kein Außen zu geben" },

	{ "start": "7", "end": "12", "text": "verschlungene Pfade der immer schon verteilten Macht" },

	{ "start": "13", "end": "17", "text": "Techniken des Erscheinens und Verschwindens" },

	{ "start": "18.5", "end": "37", "text": "Die Macht ist ihrer Zentralität beraubt und auf so unscheinbare Lokalitäten wie Schwellen mit Türhütern, Tapetentüren, oder versteckte Korridore verteilt" },

	{ "start": "38", "end": "47", "text": "Das war eine Visualisierung dessen, was wir vorhatten, nämlich dieses Haus zu öffnen" },

	{ "start": "48", "end": "58", "text": "Lady Reason responded and said, “Get up, daughter! Without waiting any longer, let us go to the Field of Letters" },

	{ "start": "59", "end": "75", "text": "There the City of Ladies will be founded on a flat and fertile plain, where all fruits and freshwater rivers are located and where the earth abounds in all good things" },

	{ "start": "76", "end": "88", "text": "Christine de Pizans Stadt der Frauen (um 1400) ist die Geschichte von der Errichtung einer idealen Stadt" },

	{ "start": "89", "end": "100", "text": "Ein imaginärer Raum mit den realen architektonischen Merkmalen einer mittelalterlichen Stadt" },

	{ "start": "101", "end": "120", "text": "Ein befestigter Zufluchtsort für Frauen, errichtet aus Geschichten und Porträts außergewöhnlicher Vertreter innen des weiblichen Geschlechts aus allen Epochen" },

	{ "start": "122", "end": "130", "text": "Im Mittelalter differenziert sich die Lehre von den Gedächtnisorten aus" },

	{ "start": "132", "end": "138", "text": "a remarkable propensity for structures that convey and connect" },

	{ "start": "139", "end": "147", "text": "Physical transit mechanisms that also operate as communication systems " },

	{ "start": "147", "end": "152", "text": "conduits or corridors for both bodies and information" },

	{ "start": "153", "end": "158", "text": "that seem to blend in to so many other spaces" },

	{ "start": "159", "end": "163", "text": "A medial space par excellence" },

	{ "start": "165", "end": "187", "text": "Den äußerlichsten, nur ganz peripheren Aspekt der Zweideutigkeit der Passagen gibt ihr Reichtum an Spiegeln, der die Räume märchenhaft ausweitet und die Orientierung erschwert" },

	{ "start": "188", "end": "196", "text": "You see me holding this shiny mirror which I carry in my right hand in place of a scepter" },

	{ "start": "197", "end": "210", "text": "thanks to this mirror, the essences, qualities, proportions, and measures of all things are known, nor can anything be done well without it" },

	{ "start": "211", "end": "217", "text": "A medial space par excellence, the Field of Letters" },

	{ "start": "218", "end": "227", "text": "It has been called a Geheimsprache, accompanied by its Geheimschrift" },

	{ "start": "229", "end": "233", "text": "one oft the earliest known constructed languages" },

	{ "start": "234", "end": "245", "text": "Hildegard von Bingen produces over a thousand unknown words to increase solidarity among her nuns" },

	{ "start": "246", "end": "259", "text": "Her illuminations, the crenellations of her architectural images, her frequent references to shimmering optical effects" },

	{ "start": "260", "end": "274", "text": "her symbol for „a“ in R vaguely resembles lowercase gamma, where as it looks like a modified „r“ in V" },

	{ "start": "275", "end": "279", "text": "her „n“ could be a version of lowercase phi" },

	{ "start": "281", "end": "284", "text": "B look more like a running figure" },

	{ "start": "286", "end": "293", "text": "“o“ is represented quite differently as a heart shape or an elaborate squiggle" },

	{ "start": "295", "end": "298", "text": "Her letters are most likely her own invention" },

	{ "start": "300", "end": "304", "text": "Haec muher est diviihtas" },

	{ "start": "305", "end": "308", "text": "Hildegard’s unknown letters" },

	{ "start": "309", "end": "319", "text": "Ich konnte mir nicht erklären wo dieser Schein herkommt, ob da jemand aus dem Baumat herunter leuchtet?" },

	{ "start": "321", "end": "325", "text": "The questions and the answers vary over the centuries" },

	{ "start": "326", "end": "336", "text": "im Kreuzgang wurden Wände abgebaut und dadurch wurde diese Glasscheibe freigelegt" },

	{ "start": "338", "end": "340", "text": "Glosso-poeia" },

	{ "start": "340", "end": "342", "text": "Glosso-poetics" },

	{ "start": "344", "end": "345", "text": "Glosso-graphia" },

	{ "start": "346", "end": "351", "text": "glōssa - Zunge, Sprache " },

	{ "start": "352", "end": "359", "text": "lalein – reden, schatzen, sprechen" },

	{ "start": "360", "end": "368", "text": "Nine timest hey cut out his tongue and nine times it was regenerated" },

	{ "start": "370", "end": "375", "text": "But now - how Angels speak it is hid from us" },

	{ "start": "376", "end": "390", "text": "Jaws, palate, lips, teeth, throat, lungs, the aspera arteria, muscles of the breast" },

	{ "start": "392", "end": "399", "text": "If you speak at a distance to another, you must use a louder voice" },

	{ "start": "400", "end": "404", "text": "but if nearer, one whispers in an ear:" },

	{ "start": "405", "end": "413", "text": "and if one could be coupled to the hearer, a softer breath would suffice;" },

	{ "start": "414", "end": "419", "text": "for one would slide into the hearer without any noise" },

	{ "start": "420", "end": "423", "text": "as an image in the eye, or glass" },

	{ "start": "425", "end": "434", "text": "So lange ich hier in der Kunsthalle tätig bin gab es Überlegungen, den Innenhof zu überdachen " },

	{ "start": "435", "end": "444", "text": "wir wollten ein Glasdach, aber das war schwierig zu verwirklichen, das Baumat war dagegen" },

	{ "start": "446", "end": "452", "text": "Den Neubau gabs ja nicht, der Innenhof war nach hinten offen" },

	{ "start": "453", "end": "467", "text": "da wurden Pferde untergebracht, es gab eine Färberei und eine Wäscherei und bis 1990 gab es dort einen Brunnen" },

	{ "start": "469", "end": "475", "text": "Ich stelle mir das ein bisschen wie einen Zeitraffer vor" },

	{ "start": "476", "end": "485", "text": "diese ganzen Bewegungen an diesem Ort - angefangen von den Dominikanermönchen, über Napoleon — " },

	{ "start": "486", "end": "492", "text": "bei Napoleon wurden hier britische Soldaten untergebracht " },

	{ "start": "493", "end": "500", "text": "dann war es ein Kornspeicher, ein Lazarett, dann ein Wohnhaus " },

	{ "start": "502", "end": "505", "text": "ein Lagerort für Theater-Requisiten" },

	{ "start": "507", "end": "511", "text": "es gab Plünderungen, Brand, Pest" },

	{ "start": "512", "end": "520", "text": "es war ständig von starken Ereignissen betroffen, wurde zerstört, wieder aufgebaut" },

	{ "start": "521", "end": "534", "text": " Gestern haben wir die Litfaßsäule, die vor der Kunsthalle steht, komplett neu gestrichen, sie war lilablau und jetzt ist sie gelb geworden " },

	{ "start": "536", "end": "542", "text": "On the other hand, the Lingua is just as interesting for what it leaves out " },

	{ "start": "544", "end": "551", "text": " Infrastructure. Glosso-Graphia and divine lists " },

	{ "start": "552", "end": "555", "text": " Jetzt ist oben mehr Luft " },

	{ "start": "558", "end": "566", "text": " This institutional space seems rendered permeable as a readable object " },

	{ "start": "568", "end": "583", "text": " Weil, jetzt ist die Decke herausgenommen worden, früher war da ein Kunststoff und dazwischen war eine Matte und das bröselte manchmal runter ein bisschen " },

	{ "start": "585", "end": "587", "text": " Jetzt ist oben mehr Luft" },

	{ "start": "589", "end": "593", "text": " This is Line Skywalker speaking " },

	{ "start": "594", "end": "599", "text": " When a woman invents language, antennas are raised" },

	{ "start": "601", "end": "608", "text": " They were disturbed by the frankness of the list of body parts in the Lingua " },

	{ "start": "610", "end": "616", "text": "Schaniz, vagina, scheida, SHEATH" },

	{ "start": "618", "end": "621", "text": " Zamiziz, FOLDS - (in clothing)" },

	{ "start": "623", "end": "630", "text": " Rogazin, pellicium, belliz, FUR" },

	{ "start": "632", "end": "647", "text": " Despite the often random quality of her imaginary words, it focuses on the ordered nature of her prefixes, compounds, and gendered endings " },

	{ "start": "648", "end": "659", "text": " elsewhere she depicts the lusts of men and women in an equally unexpected manner " },

	{ "start": "660", "end": "661", "text": " like" },

	{ "start": "662", "end": "664", "text": "WANDELTREIBER:INNEN" },

	{ "start": "666", "end": "670", "text": " Ein Kreuzgang ist eine Wandelhalle " },

	{ "start": "672", "end": "679", "text": " Wandeln, Um-wandeln, Ab-wandeln, Verwandeln" },

	{ "start": "681", "end": "692", "text": " a corridor referred not to a space but to a courier, someone who - as the Latin word suggests - could run fast" },

	{ "start": "694", "end": "695", "text": " Courierings" },

	{ "start": "696", "end": "697", "text": " Kurierdienste" },

	{ "start": "700", "end": "705", "text": " Wir arbeiten daran, der Flurfunk findet wieder statt" },

	{ "start": "707", "end": "710", "text": " wir teilen ein einziges Gehirn " },

	{ "start": "710", "end": "714", "text": " für eine gemeinsame Textproduktion " },

	{ "start": "717", "end": "720", "text": " Diskriminierung verlernen" },

	{ "start": "721", "end": "723", "text": " und wessen Freiheit ist die Norm?" },

	{ "start": "725", "end": "757", "text": " Euer großes Interesse an institutionsbezogenem Handeln, das finde ich sehr besonders und ein politisches Moment Eurer Arbeit als Direktorinnen. Das ist eine Herausforderung, der ihr Euch sehr bewusst stellt, mit allen Schwierigkeiten die das mit sich bringt" },

	{ "start": "769", "end": "769", "text": " Eine queer-feministische Institutionalität? Wie ein feministischer building code…." },

	{ "start": "772", "end": "784", "text": " Gestehen wir dem scheinbar bürokratischen Charakter von Barrierefreiheit eine sinnliche Rolle zu " },

	{ "start": "786", "end": "793", "text": " Institutional Matterings, it matters, you make it matter " },

	{ "start": "796", "end": "800", "text": " Dafür habt ihr ja dann auch den Korridor " },

	{ "start": "801", "end": "809", "text": " Aber wohin führen die Leitungen und was genau läuft da durch?" },

	{ "start": "811", "end": "817", "text": " Eine besondere Ortskenntnis ist erforderlich " },

	{ "start": "818", "end": "829", "text": " Nämlich das Gehen, als körpertheoretische Äußerung, die man aber auch gemeinschaftlich vollziehen kann " },

	{ "start": "831", "end": "840", "text": " Normalerweise gehen wir so langsam wie möglich, das ist die Handlungsanweisung " },

	{ "start": "841", "end": "853", "text": " von der Straße kommend durch den Haupteingang wie durch eine Schleuse das Foyer querend " },

	{ "start": "853", "end": "856", "text": " in den Kreuzgang tretend" },

	{ "start": "857", "end": "863", "text": " und sofort eine Tür öffnend in Richtung des Neubaus " },

	{ "start": "865", "end": "869", "text": " über den Innenhof wieder in den Kreuzgang " },

	{ "start": "870", "end": "883", "text": " dann auf der anderen Seite hinter der Glasscheibe durch den Neubau auf die Neue Straße zugehend " },

	{ "start": "884", "end": "895", "text": " Eure Leitungen sind vielleicht als so ein Pendant zu den Bildprogrammen mittelalterlicher Kreuzgänge denkbar?" },

	{ "start": "897", "end": "899", "text": " Das Bildprogramm" },

	{ "start": "900", "end": "901", "text": "pigmentarii" },

	{ "start": "902", "end": "903", "text": "vivens" },

	{ "start": "905", "end": "906", "text": "mythopoeia" },

	{ "start": "908", "end": "913", "text": " They will speak in new tongues; they will pick up serpents;" },

	{ "start": "915", "end": "921", "text": " davor war es eine Mehrzweckhalle mit Taubenzüchtervereinen" },

	{ "start": "923", "end": "933", "text": " und vielleicht ist es jetzt ein bisschen verbaut, verschüttet und verstellt, aber im Endeffekt funktioniert es trotzdem " },

	{ "start": "936", "end": "941", "text": "Und wir haben unendlich viele Kreuzgänge begangen" },

	{ "start": "943", "end": "955", "text": " von dort führen - gleichsam auf dem kurzen Dienstweg - Geheimtreppen in die darüber liegenden Gemächer " },

	{ "start": "957", "end": "961", "text": " with silk veils and golden crowns " },

	{ "start": "962", "end": "968", "text": " Glockenstrang, Klingelzug, Haustelefon " },

	{ "start": "969", "end": "972", "text": " a medial space par excellence " },

	{ "start": "973", "end": "984", "text": " Eine Geometrie des Raums, in dem Funken und nichts als Funken ihre Spur hinterlassen " },

	{ "start": "986", "end": "989", "text": " like Telegram and Telephone " },

	{ "start": "990", "end": "997", "text": " they destroy the cosmos by undoing the space required for devotion and reflection " },

	{ "start": "998", "end": "1000", "text": "telepathy" },

	{ "start": "1002", "end": "1003", "text": "Telepaths" },

	{ "start": "1004", "end": "1006", "text": "tele pathos" },

	{ "start": "1007", "end": "1011", "text": "poetische Petitessen" },

	{ "start": "1013", "end": "1018", "text": "She is merely channeling a new semiotic given to her" },

	{ "start": "1019", "end": "1024", "text": "Without waiting any longer, let us go to the Field of Letters" },

	{ "start": "1026", "end": "1038", "text": "Wir wissen lediglich, dass sie etwa ab 1394 zu schreiben beginnt, zunächst einige Balladen" },

	{ "start": "1038", "end": "1051", "text": "In mittelterlichen Heiligenlegenden sind Erzählungen von weiblichen Travestien, vom Überleben in Männerkleidung häufig" },

	{ "start": "1053", "end": "1057", "text": "Christine de Pizan war diese Tradition bestens vertraut" },

	{ "start": "1059", "end": "1074", "text": "erzählt sie doch in der Stadt der Frauen die Geschichte der Jungfrau Marina, die als Mönch Marinus ein Leben in vollkommener Frömmigkeit führte" },

	{ "start": "1077", "end": "1092", "text": "Sie verfügte wahrscheinlich über ein Scriptorium, eine mittelalterliche Schreibstätte, in der sie mit zwei Kopistinnen arbeitete" },

	{ "start": "1095", "end": "1101", "text": "Deshalb kann sie als Ahn-Herrin moderner Verlegerinnen gelten" },

	{ "start": "1104", "end": "1109", "text": "Kluge Frauen, die sich viel getraut haben" },

	{ "start": "1111", "end": "1125", "text": "The women she addressed ranged from those with power and authority to the poorest peasant women, including widows, prostitutes and nuns" },

	{ "start": "1127", "end": "1144", "text": "That is reminding us that Hildegard herself, with her frequent use of cross-sexual imagery and inversion, instinctively avoided the peril of associating the feminine exclusively with women" },

	{ "start": "1146", "end": "1156", "text": "Und da gab es 2 Äbtissinnen, die gleichberechtigt das Kloster leiten, immer schon" },


];